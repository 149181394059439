import React, { useState } from "react";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";

const photos = [
	{ src: "./images/flex/hero.jpg", alt: "image 1", width: 1440, height: 830 },
	{ src: "./images/gallery/gallery-1.jpg", alt: "image 2", width: 778, height: 827 },
	{ src: "./images/flex/image-block.jpg", alt: "image 2", width: 1062, height: 701 },
	{ src: "./images/mag/video-overlay.jpg", alt: "image 3", width: 1440, height: 617 },
	{ src: "./images/gallery/gallery-4.jpg", alt: "image 1", width: 1700, height: 1374 },
	{ src: "./images/home/video-2.jpg", alt: "image 4", width: 2400, height: 1333 },
	{ src: "./images/home/video-1.jpg", alt: "image 4", width: 1062, height: 697 },
	{ src: "./images/home/hero-featured.jpg", alt: "image 7", width: 925, height: 800 },
	{ src: "./images/home/lens-1.jpg", alt: "image 1", width: 416, height: 740 },
	{ src: "./images/flex/image-block.jpg", alt: "image 2", width: 1062, height: 701 },
	{ src: "./images/mag/video-overlay.jpg", alt: "image 3", width: 1440, height: 617 },
	{ src: "./images/home/video-3.jpg", alt: "image 4", width: 1800, height: 735 },
	{ src: "./images/home/lens-3.jpg", alt: "image 1", width: 416, height: 740 },
];

export default function Gallery() {
	const [index, setIndex] = useState(-1);

	return (
		<>
			<PhotoAlbum photos={photos} layout="rows" onClick={({ index }) => setIndex(index)} />
			<Lightbox
				slides={photos}
				open={index >= 0}
				index={index}
				close={() => setIndex(-1)}
				// enable optional lightbox plugins
				plugins={[Fullscreen]}
			/>
		</>
	);
}